'use client'

import { Fragment } from 'react'
import { Tooltip } from 'react-tooltip'

interface IntProps {
  children: React.ReactNode
}

export default function TooltipProviderWrapper({ children }: IntProps) {
  return (
    <Fragment>
      {children}

      <Tooltip
        anchorSelect=".standart-tooltip"
        opacity={1}
        className="z-[100] max-w-xs"
      />
    </Fragment>
  )
}
