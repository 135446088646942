'use client'

import { createContext, useState } from 'react'
import { EnCookieName } from 'src/enums/cookie-name.enum'
import { EnUserSetting } from 'src/enums/user-setting.enum'
import {
  changeCookieSetting,
  defaultUserSettings,
} from 'src/helpers/provider.helper'
import { ContextType, UserSettingsType } from './types'

export const UserSettingsContext = createContext<ContextType>({
  userSettings: defaultUserSettings,
  changeUserSetting: (name: EnUserSetting, value: any) => {},
})

export default function UserSettingsProvider(props: {
  children: React.ReactNode
  settings: UserSettingsType
}) {
  const [userSettings, setUserSettings] = useState<UserSettingsType>(
    props.settings,
  )

  return (
    <UserSettingsContext.Provider
      value={{
        userSettings,
        changeUserSetting: (name, value) => {
          setUserSettings({ ...userSettings, [name]: value })
          changeCookieSetting({
            cookieName: EnCookieName.USER_SETTINGS,
            name,
            value,
          })
        },
      }}
    >
      {props.children}
    </UserSettingsContext.Provider>
  )
}
