import { getCookie, setCookie } from 'cookies-next'
import { ChapterSettingsType } from 'src/app/providers/ChapterSettingsProvider/types'
import { UserSettingsType } from 'src/app/providers/UserSettingsProvider/types'
import { EnChapterSetting } from 'src/enums'
import { EnCookieName } from 'src/enums/cookie-name.enum'
import { EnUserSetting } from 'src/enums/user-setting.enum'

export const defaultChaperSettings: ChapterSettingsType = {
  maxWidth: 900,
  fontSize: 17,
  textIndent: 14,
  lineHeight: 1.6,
  textAlign: 'left',
}

export const defaultUserSettings: UserSettingsType = {
  isAdult: false,
}

export const getDefaultProviderSettings = (cookieName: EnCookieName) => {
  switch (cookieName) {
    case EnCookieName.USER_SETTINGS:
      return defaultUserSettings

    case EnCookieName.CHAPTER_SETTINGS:
      return defaultChaperSettings

    default:
      return {}
  }
}

export const changeCookieSetting = (params: {
  cookieName: EnCookieName
  name: EnChapterSetting | EnUserSetting
  value: string | number
}) => {
  const settings = getCookie(params.cookieName)

  const defaultSettings = getDefaultProviderSettings(params.cookieName)

  if (!settings) {
    return setCookie(
      params.cookieName,
      JSON.stringify({ ...defaultSettings, [params.name]: params.value }),
    )
  }

  return setCookie(
    params.cookieName,
    JSON.stringify({
      ...defaultSettings,
      ...JSON.parse(String(settings)),
      [params.name]: params.value,
    }),
  )
}
