import { MoonIcon, SunIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { memo, useContext } from 'react'
import { EnTheme } from 'src/enums'
import { ThemeContext } from 'src/providers/ThemeProvider'

export const Theme = memo(function Theme(props: { className?: string }) {
  const { theme, toggleTheme } = useContext(ThemeContext)
  const IconComponent = theme === EnTheme.DARK ? SunIcon : MoonIcon

  return (
    <button
      onClick={toggleTheme}
      className={clsx(
        'grid hover cursor-pointer text-gray-primary content-center md:hover:text-primary md:dark:hover:bg-black-600 md:hover:bg-gray-200/50 px-2.5',
        props.className,
      )}
    >
      <IconComponent className="w-5" />
    </button>
  )
})
