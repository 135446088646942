'use client'

import { usePathname } from 'next/navigation'

const FooterWrapper = (props: { children: React.ReactNode }) => {
  const pathname = usePathname()
  const regex = /ch\d+/
  const match = pathname?.match(regex) || pathname?.includes('/user/')

  if (match) {
    return null
  }

  return props.children
}

export default FooterWrapper
