'use client'

import { Book, Chapter } from '@prisma/client'
import { Session } from 'next-auth'
import dynamic from 'next/dynamic'
import { useParams } from 'next/navigation'
import { fetcher } from 'src/app/lib/fetcher'
import useSWR from 'swr'

const DesktopMenu = dynamic(() => import('./DesktopMenu'), {
  ssr: false,
})

const ChapterContainer = dynamic(() => import('./ChapterContainer'), {
  ssr: false,
})

export default function HavContainer(props: { session: Session | null }) {
  const params = useParams<{ bookSlug?: string; id?: string }>()

  const { data: chapter } = useSWR<Chapter & { book: Book }>(
    params?.bookSlug && params?.id
      ? {
          url: `/chapters/${params.id.replace('ch', '')}?${new URLSearchParams({
            include: 'book',
          }).toString()}`,
        }
      : null,
    fetcher<Chapter & { book: Book }>,
    {},
  )

  // not chapter page
  if (!params?.bookSlug || !params?.id) {
    return <DesktopMenu isAuth={!!props.session} />
  }

  if (!chapter) return null

  return <ChapterContainer chapter={chapter} />
}
