'use client'

import { Session } from 'next-auth'
import { useContext } from 'react'
import { Icon } from 'src/components/common/Icon'
import { Button } from 'src/components/ui/Button'
import { EnModal } from 'src/enums'
import { t } from 'src/helpers/translate.helper'
import { ModalContext } from 'src/providers/ModalProvider'
import { Theme } from './Theme'
import { UserPopover } from './UserPopover'

export default function UserContainer(props: { session: Session | null }) {
  const { toggleModal } = useContext(ModalContext)

  return (
    <div className="flex justify-end gap-x-2 h-full flex-grow">
      {!props.session && (
        <>
          <Theme className="max-md:hidden" />

          <Button
            className="h-8 my-auto max-md:hidden"
            icon={<Icon name="logIn" className="w-4" />}
            title={t('Login')}
            onClick={() => toggleModal(EnModal.AUTH)}
          />
        </>
      )}

      <UserPopover toggleModal={toggleModal} session={props.session} />
    </div>
  )
}
