export enum EnMenuItemType {
  SEPARATOR = 'separator',
  LINK = 'link',
  BUTTON = 'button',
}

type MenuItemBase = {
  className?: string
  isActive?: boolean

  onClick?: () => void
}

type MenuItemSeparator = MenuItemBase & {
  type: EnMenuItemType.SEPARATOR
}

export type MenuItemButton = MenuItemBase & {
  type: EnMenuItemType.BUTTON
  title: string | JSX.Element
  icon?: JSX.Element
  subTitle?: JSX.Element
}

export type MenuItemLink = MenuItemBase & {
  type: EnMenuItemType.LINK
  title: string | JSX.Element
  icon?: JSX.Element
  subTitle?: JSX.Element
  href: string
}

export type MenuItemType = MenuItemSeparator | MenuItemButton | MenuItemLink
