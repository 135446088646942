'use client'

import { Store } from 'react-notifications-component'
import { EnToastType } from 'src/enums'

export const handleExceptionRequest = (
  error: Error & { errors?: Record<string, string> },
  displayToastr = true,
) => {
  const errorMessage =
    error.message ??
    Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ')

  console.error('error', errorMessage)

  if (displayToastr) {
    return addToast(errorMessage, EnToastType.DANGER)
  }

  return errorMessage
}

export const addToast = (
  message: string,
  type: EnToastType = EnToastType.DANGER,
) => {
  Store.addNotification({
    message,
    type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  })
}

export const addToastLogin = async (text: string) => {
  return addToast(text, EnToastType.WARNING)
}
