import { useCallback, useContext } from 'react'
import { addToastLogin } from 'src/app/lib/utils/toast.util'
import { EnModal } from 'src/enums'
import { sleep } from 'src/helpers/main.helper'
import { t } from 'src/helpers/translate.helper'
import { ModalContext } from 'src/providers/ModalProvider'

export const useLogin = () => {
  const { toggleModal } = useContext(ModalContext)

  const displayLoginPopup = useCallback(
    async (isDisplayToast: boolean = true) => {
      toggleModal(EnModal.AUTH)
      if (isDisplayToast) {
        await sleep(500)
        addToastLogin(t('Need to auth'))
      }
    },
    [toggleModal],
  )

  return {
    displayLoginPopup,
  }
}
