'use client'

import { SessionProvider } from 'next-auth/react'

export default function SessionProviderWrapper(props: {
  children: React.ReactNode
}) {
  return (
    <SessionProvider refetchOnWindowFocus>{props.children}</SessionProvider>
  )
}
