'use client'

import { usePathname } from 'next/navigation'
import { Fragment, useEffect, useState } from 'react'

export default function RouteEvent() {
  const pathname = usePathname()
  const [route, setRoute] = useState(pathname)

  useEffect(() => {
    if (pathname !== route) {
      if (!window.ym) {
        console.log('window.ym not found')
      }

      window.ym &&
        window.ym(
          +process.env.NEXT_PUBLIC_ENV_YANDEX_METRICS_ID,
          'hit',
          process.env.NEXT_PUBLIC_ENV_DOMAIN + pathname,
        )
    }
    setRoute(pathname)
  }, [pathname, route])

  return <Fragment />
}
