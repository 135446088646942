'use client'

import dynamic from 'next/dynamic'
import { createContext, useState } from 'react'
import { EnModal } from 'src/enums'
import { IntContext, ModalDataT } from './types'

const Modal = dynamic(() => import('src/components/Modal'), {
  ssr: false,
})

export const ModalContext = createContext<IntContext>({
  activeModal: null,
  data: {},
  toggleModal: () => {},
})

interface IntProps {
  children: React.ReactNode
}

export default function ModalProvider({ children }: IntProps) {
  const [modalInfo, setModalInfo] = useState<{
    activeModal: EnModal | null
    data: ModalDataT
  }>({ activeModal: null, data: {} })

  return (
    <ModalContext.Provider
      value={{
        activeModal: modalInfo.activeModal,
        data: modalInfo.data,
        toggleModal: (activeModal, data) =>
          setModalInfo({ activeModal, data: data || {} }),
      }}
    >
      {children}

      {modalInfo.activeModal && (
        <Modal
          data={modalInfo.data}
          activeModal={modalInfo.activeModal}
          setActiveModal={(activeModal) =>
            setModalInfo({ activeModal, data: {} })
          }
        />
      )}
    </ModalContext.Provider>
  )
}
