import clsx from 'clsx'
import { ReactNode, memo } from 'react'

export const Button = memo(function Button(props: {
  title: string
  className?: string
  isPrimary?: boolean
  icon?: ReactNode
  disabled?: boolean

  onClick: () => void
}) {
  const { isPrimary = true } = props

  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className={clsx(
        'grid-flow-col disabled:cursor-not-allowed disabled:opacity-50 gap-x-3 items-center rounded-md px-2 h-10 grid',
        props.className,
        {
          'text-white dark:text-black-1000 hover:!bg-opacity-75 bg-primary':
            isPrimary,
        },
      )}
    >
      {props.icon}
      {props.title}
    </button>
  )
})
