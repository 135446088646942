'use client'

import { useParams } from 'next/navigation'
import { createContext, useEffect, useState } from 'react'
import { useScrollDirection } from 'src/hooks'
import { IntContext } from './types'

export const HeaderContext = createContext<IntContext>({
  isShow: true,
  isTransparentBg: false,
  setIsShow: () => {},
})

export default function HeaderProvider(props: { children: React.ReactNode }) {
  const { children } = props
  const params = useParams<{ bookSlug?: string; id?: string }>()
  const isChapterPage = Boolean(params?.bookSlug && params?.id)
  const isBookPage = Boolean(params?.bookSlug && !params.id)

  const [isShow, setIsShow] = useState(true)
  const [isTransparentBg, setIsTransparentBg] = useState(false)
  const direction = useScrollDirection(!!isChapterPage)

  useEffect(() => {
    setIsTransparentBg(isBookPage ? window.scrollY === 0 : false)
    setIsShow(true)
  }, [params, isBookPage])

  useEffect(() => {
    if (isChapterPage) setIsShow(!direction)
  }, [direction, isChapterPage])

  useEffect(() => {
    if (!isBookPage) return

    const handleScrollBook = () => setIsTransparentBg(window.scrollY === 0)

    window.addEventListener('scroll', handleScrollBook)
    return () => {
      window.removeEventListener('scroll', handleScrollBook)
    }
  }, [isBookPage])

  return (
    <HeaderContext.Provider
      value={{
        isShow,
        isTransparentBg,
        setIsShow,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}
