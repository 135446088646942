'use client'

import clsx from 'clsx'
import { useContext } from 'react'
import { HeaderContext } from 'src/providers/HeaderProvider'

export default function HeaderWrapper(props: { children: React.ReactNode }) {
  const { isShow, isTransparentBg } = useContext(HeaderContext)

  return (
    <header
      className={clsx(
        ' bg-white transition-all duration-200 dark:bg-black-700 z-20 top-0 w-full max-md:fixed md:sticky',
        {
          'max-md:!bg-transparent max-md:!shadow-none': isTransparentBg,
          'translate-y-0 shadow dark:shadow-black-1000': isShow,
          '-translate-y-full': !isShow,
        },
      )}
    >
      {props.children}
    </header>
  )
}
